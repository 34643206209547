<template>
    <div class="main-body">
        
        <h2 class="collection-name">Shop {{collectionName}}</h2>
        <p class="no-results-text" v-if="filteredList!=undefined&&filteredList.length==0">No Results Found!</p>
        <div class="filter-body">
            <!-- Desktop Filter -->
            <div class="filter-container-wrapper" :style="filterStyle">
                <div class="filter-container" v-if="filteredList!=undefined&&filteredList.length>0">
                    <div class="filter-header-box">
                        <p class="filter-header">Filters</p>
                        <p class="clear-filter" @click="resetFilter()">Clear All</p>
                    </div>
                    <div class="filter-categories-container">

                        <div class="filter-category-box" v-for="(value, name) in filterDisplay" :key="name">
                            <p class="filter-category">{{name}}</p>
                            <div class="filter-list-box">
                                <li class="filter-list-item" v-for="item in value" :key="item">
                                    <FilterItem :checked="getCheckedValue(name, item)" :item="item" :type="name" @item-checked="filterChecked" @item-unchecked="filterUnchecked"/>
                                </li>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Portrait Filter -->
            <div class="portrait-filter" v-if="filteredList!=undefined&&filteredList.length>0">
                <p class="portrait-filter-button" v-if="portraitFilter=='close'">
                    <span><svg xmlns="http://www.w3.org/2000/svg" class="portrait-filter-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" /></svg></span>
                    <span class="portrait-filter-text" @click="openPortraitFilter()">Filter</span>
                </p>
                <p class="close-filter" v-if="portraitFilter=='open'" @click="closePortraitFilter()">Apply</p>
            </div>

            <div class="collection-result-wrapper">
                <ProductGrid :productGridData="filteredList"/>
            </div>
            

        </div>


    </div>
</template>

<script>
import ProductGrid from '@/components/product/ProductGrid.vue'
import FilterItem from '@/components/collection/FilterItem.vue'
import { getCollectionFileUrl } from "@/apis/collection";
import { getCollectionFile } from "@/apis/collection";

export default {
    name: 'Collection',
    components: { ProductGrid, FilterItem },
    data(){
        return{
            collectionName: '',
            collectionsList: undefined,
            filterStyle: {},
            filteredList: undefined,
            filterDisplay: {"category": [], "gender": [], "priceRange": [], "color": []},
            selectedFilter: [{"category": [], "gender": [], "priceRange": [], "color": []}],
            portraitFilter: 'close',
            gender: undefined
        }
    },
    mounted(){
        this.getCollection();
        this.collectionName = this.$route.params["id"].replace("-", " ")
    },
    methods:{
        getParams(){
			var url = new URL(window.location.href);
			this.gender = url.searchParams.get("gender");

			if(this.gender == "men"){
                this.filterChecked("gender", "men");
			}
            else if(this.gender == "women"){
                this.filterChecked("gender", "women");
			}
		},
        // Get Collection Data from Firebase Storage
        async getCollection(){
            let currentTime = Math.floor(Date.now() / 1000);
            let collectionsLastUpdated = localStorage.getItem("collectionsLastUpdated");
            if((collectionsLastUpdated == null) || (currentTime-collectionsLastUpdated)>86400){
                console.log(collectionsLastUpdated, currentTime-collectionsLastUpdated);
                let self = this;
                let url = await getCollectionFileUrl('assets/files/collection/all.json');
                this.collectionsList = await getCollectionFile(url);

                localStorage.setItem("collections", JSON.stringify(self.collectionsList));
                localStorage.setItem("collectionsLastUpdated", currentTime);
            }
            else{
                this.collectionsList = JSON.parse(localStorage.getItem("collections"));
            }
            
            this.filterCollectionData();
        },
        // Filter data in Collection
        filterCollectionData(){
            if(this.$route.params["id"] != "all"){
                var temp = [];

                for(let i in this.collectionsList){
                    if(this.collectionsList[i]["collection"].includes(this.$route.params["id"]) && (!temp.includes(this.collectionsList[i])) ){
                        temp.push(this.collectionsList[i]);
                    }
                }
                this.collectionsList = temp;
            }
            
            this.filteredList = this.collectionsList;
            this.getFilterData();
        },
        // Get Filter parameters from Product data
        getFilterData(){
            for(let i in this.collectionsList){
                for(var j in this.filterDisplay){
                    if(!this.filterDisplay[j].includes(this.collectionsList[i][j])){
                        this.filterDisplay[j].push(this.collectionsList[i][j]);
                    }
                    
                    if(this.selectedFilter[0][j].indexOf(this.collectionsList[i][j]) == -1){
                        this.selectedFilter[0][j].push(this.collectionsList[i][j]);
                    }
                }
            }
            // Sort price range
            this.filterDisplay["priceRange"] = this.filterDisplay["priceRange"].sort(function(a, b){
                if(a < b) { return -1; }
                if(a > b) { return 1; }
                return 0;
            })

            this.getParams();
        },
        getCheckedValue(name, item){
            if(name == "gender" && item == this.gender){
                return true
            }
            else{
                return false
            }
        },
        openPortraitFilter(){
            this.filterStyle = {"display": "block"}
            this.portraitFilter = 'open';
        },
        closePortraitFilter(){
            this.filterStyle = {}
            this.portraitFilter = 'close';
        },
        // Function to apply checked filters
        filterChecked(type, val){
            if(this.filterDisplay[type].length == this.selectedFilter[0][type].length){
                this.selectedFilter[0][type] = [];
            }
            
            if(!this.selectedFilter[0][type].includes(val)){
				this.selectedFilter[0][type].push(val);
                this.filterListData();
			}
        },
        // Function to remove checked filters
        filterUnchecked(type, val){
            const index = this.selectedFilter[0][type].indexOf(val);
			if (index != -1) {
				this.selectedFilter[0][type].splice(index, 1);
                if(this.selectedFilter[0][type].length == 0){
                    this.selectedFilter[0][type] = this.filterDisplay[type]
                }
                this.filterListData();
			}
        },
        // Reset all filter
        resetFilter(){
            let self = this;
            
            this.filteredList = this.collectionsList;
            this.getFilterData();
            var temp = this.filterDisplay;
            this.filterDisplay = [];
            setTimeout(function(){ self.filterDisplay = temp; }, 50);
        },
        // Apply Selected Filter
        filterListData(){
            let self = this;

            this.filteredList = this.collectionsList.filter(function(item){
                return self.selectedFilter[0]["category"].indexOf(item.category) > -1 && self.selectedFilter[0]["gender"].indexOf(item.gender) > -1 && self.selectedFilter[0]["priceRange"].indexOf(item.priceRange) > -1 && self.selectedFilter[0]["color"].indexOf(item.color) > -1;
            });
        }
    }
}
</script>

<style scoped>
    .main-body{max-width: 1280px;margin: auto;padding: 0 12px;min-height: 100vh;padding: 48px 0px;}
    .collection-name{text-transform: capitalize;padding: 12px;margin-bottom: 12px;}

    .filter-body{display: grid;grid-template-columns: 1fr 4fr;min-height: 50vh;}
    /* Filter Box */
    .filter-container-wrapper{}
    .portrait-filter{display: none;}
    .filter-container{background-color: #fff;margin-right: 32px;}
    .filter-header-box{position: relative;margin: 0;padding: 12px 16px;font-size: 15px;}
    .filter-header{margin: 0;text-transform: uppercase;font-weight: 600;}
    .clear-filter{margin: 0;position: absolute;right: 0;top: 0;padding: 12px 16px;font-size: 13px;color: #3e50b5;cursor: pointer;line-height: 16px;font-weight: 600;}
    .filter-categories-container {text-align: left;background-color: #fff;width: 100%;padding-top: 16px;}
    .filter-category-box{padding: 16px;padding-bottom: 8px;border-top: 1px solid #e4e4e4;}
    .filter-category{margin: 0;font-weight: 500;font-size: 14px;color: #4b5563;margin-bottom: 12px;text-transform: capitalize;}
    .filter-list-box{}
    .filter-list-item{list-style: none;}
    .filter-list-item-color{list-style: none;width: 20px;height: 20px;display: inline-block;margin-right: 4px;font-family: 'Roboto', sans-serif;}
    .check-input-container {display: block;position: relative;padding-left: 24px;margin-bottom: 8px;cursor: pointer;font-size: 22px;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;text-transform: capitalize;}
    .filter-item{font-size: 14px;vertical-align: top;line-height: 16px;}
    .check-input-container input {position: absolute;opacity: 0;cursor: pointer;height: 0;width: 0;}
    .checkmark{position: absolute;top: 0;left: 0;height: 15px;width: 15px;border: 1px solid #c7c7c7;border-radius: 3px;}
    .checkmark-color{position: absolute;top: 0;left: 0;border-radius: 3px;width: 20px;height: 20px;border: 1px solid #cecece;}
    .yellow{background-color: #fb0;}
    .blue{background-color: #3b8beb;}
    .red{background-color: #e82b3a;}
    .white{background-color: #fff;}
    .check-input-container input:checked ~ .checkmark {background-color: #3e50b5;}
    .check-input-container input:checked ~ .checkmark-color {border: 1px solid #3a3a3a;}
    .checkmark:after {content: "";position: absolute;display: none;}
    .check-input-container input:checked ~ .checkmark:after {display: block;}
    .check-input-container input:checked ~ .checkmark{border: none;}
    .check-input-container .checkmark:after {left: 5px;top: 2px;width: 5px;height: 9px;border: solid white;border-radius: 1px;border-width: 0 2px 2px 0;-webkit-transform: rotate(45deg);-ms-transform: rotate(45deg);transform: rotate(45deg);}

    .collection-result-wrapper{position: relative;}
    .no-results-text{margin: 0;position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);}


    /* Tablet */
    @media only screen and (max-width: 1024px){
      .filter-body{grid-template-columns: 220px 1fr;}
      .filter-container{margin-right: 4px;}
    }

    /* Phone */
    @media only screen and (max-width: 600px){
        .main-body{padding-top: 24px;}
        .filter-body{display: block;}
        .filter-container-wrapper{display: none;}
        .collection-result-wrapper{min-height: calc(100vh - 250px);}

        .portrait-filter{position: fixed;display: block;width: 100%;height: 56px;background-color: #fff;bottom: 0;z-index: 10;box-shadow: 0 0 #0000, 0 0 #0000, 0 4px 6px -1px rgb(0 0 0 / 10%), 0 -4px 8px -1px rgb(0 0 0 / 6%);}
        .portrait-filter-button, .close-filter{text-align: center;padding: 19px 0px;text-transform: uppercase;}
        .portrait-filter-icon{width: 16px;}
        .portrait-filter-text{margin-left: 8px;font-size: 18px;text-transform: uppercase;vertical-align: middle;font-weight: 500;}
        .filter-container-wrapper{position: absolute;top: 0;width: 100vw;z-index: 10;}
        .filter-container{min-height: 100vh;margin: 0px;}
        .filter-header-box, .clear-filter{padding: 18px;}
        .filter-categories-container{padding-top: 0px;}

    }

</style>