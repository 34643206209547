<template>
    <div class="filter-item-wrapper" @click="applyCheck()">
        <p class="filter-checkbox-checked" v-if="isChecked"></p>
        <p class="filter-checkbox-unchecked" v-if="!isChecked"></p>
        <label v-if="name!=undefined" class="filter-label">{{name}}</label>
    </div>
</template>

<script>
export default {
    name: 'FilterItem',
    data(){
        return{
            isChecked: false,
            name: undefined
        }
    },
    props:{
        checked: {},
        item: {},
        type: {}
    },
    mounted(){
        this.isChecked = this.checked;
        if(this.type == "priceRange"){
            this.name = this.getPriceRangeText(this.item);
        }
        else{
            this.name = this.item;
        }
    },
    methods:{
        // Filter Item is Checked or Unchecked
        applyCheck(){
            if(this.isChecked){
                this.isChecked = false;
                this.$emit("item-unchecked", this.type, this.item);
            }
            else{
                this.isChecked = true;
                this.$emit("item-checked", this.type, this.item);
            }
        },
        // Generate Price Range Text
        getPriceRangeText(val){
            if(val == "a"){
                return "Less than $20.00"
            }
            else if(val == "b"){
                return "$20.00 - $50.00"
            }
            else{
                return "More than $50.00"
            }
        },
    }
}
</script>

<style scoped>
    .filter-item-wrapper{display: flex;margin: 10px 0px;cursor: pointer;}
    .filter-checkbox-unchecked{width: 16px;height: 16px;border: 1px solid #d1d5db;border-radius: 4px;}
    .filter-checkbox-checked{background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");width: 16px;height: 16px;border-color: transparent;background-size: 100% 100%;background-position: center;background-repeat: no-repeat;border-radius: 3px;background-color: #6366f1;}
    .filter-label{margin-left: 12px;font-size: 13px;color: #4b5563;font-weight: 300;text-transform: capitalize;line-height: 16px;}
</style>